import React from 'react';
import Img from 'gatsby-image';
import {graphql, useStaticQuery} from 'gatsby';
import contentStyles from './content.module.css';

const Content1 = () => {
  const data = useStaticQuery(graphql`
      query {
          placeholderImage: file(relativePath: {eq: "content1.jpg"}) {
              childImageSharp {
                  fluid(maxWidth: 720) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
      }
  `);

  return (
      <section className={contentStyles.main}>
        <Img className={contentStyles.image}
             fluid={data.placeholderImage.childImageSharp.fluid}/>

        <div className={`container ${contentStyles.container}`}>
          <div className="container__inside" data-sal="fade-in"
               data-sal-delay="300"
               data-sal-easing="ease">
            <h1>Wir <b>schaffen</b> die<br/> besten <b>Verbindungen.</b></h1>
            <p>Wir gehören zu den größten Kunststoffschlauch-Herstellern
              weltweit und sind bekannt
              für unsere intelligenten und flexiblen Lösungen. Neben einer
              breiten Palette hochwertiger
              Standardprodukte und Dienstleistungen, bietet wir maßgeschneiderte
              Lösungen, die Sie
              perfekt bei der Bewältigung Ihrer spezifischen Herausforderungen
              unterstützen.</p>
          </div>
        </div>
      </section>
  );
};

export default Content1;
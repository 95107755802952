import React from 'react';
import contentStyles from './content.module.css';
import {graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';

const Content6 = () => {
  const data = useStaticQuery(graphql`
      query {
          image: file(relativePath: {eq: "content6.jpg"}) {
              childImageSharp {
                  fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
      }
  `);

  return (
      <section className={contentStyles.main}>

        <div className={contentStyles.textContainer}
             data-sal="fade-in"
             data-sal-delay="0"
             data-sal-easing="ease">
          <div className={contentStyles.textContainerInside}>
            <h2><b>Und das ist noch nicht alles …</b><br/>
              Sie finden uns weltweit.</h2>
          </div>
        </div>


        <div className={contentStyles.imageWrap}>
          <a href="https://www.flexaust.com/" target="_blank" rel="noreferrer" className={contentStyles.imageLink2}>Flexaust Inc.</a>
          <a href="https://www.flexaust.com/" target="_blank" rel="noreferrer" className={contentStyles.imageLink3}>Flexaust Inc.</a>
          <a href="https://www.flexaust.com/" target="_blank" rel="noreferrer" className={contentStyles.imageLink4}>Flexaust Inc.</a>
          <a href="https://www.flexaust.com/" target="_blank" rel="noreferrer" className={contentStyles.imageLink5}>Flexaust Inc.</a>
          <a href="https://www.flexaust.com/" target="_blank" rel="noreferrer" className={contentStyles.imageLink6}>Flexaust Inc.</a>
          <a href="https://www.flexaust.com/" target="_blank" rel="noreferrer" className={contentStyles.imageLink7}>Flexaust Inc.</a>
          <Img className={contentStyles.image}
               fluid={data.image.childImageSharp.fluid}/>
        </div>

        <div className={contentStyles.textContainer3}
             data-sal="fade-in"
             data-sal-delay="0"
             data-sal-easing="ease">
          <div className={contentStyles.textContainerInside}>
          </div>
        </div>

        <div className={contentStyles.textContainer2}
             data-sal="fade-in"
             data-sal-delay="0"
             data-sal-easing="ease">
          <div className={contentStyles.textContainerInside}>
            <h3>United States</h3>
            <p><a href="https://www.flexaust.com/" target="_blank" rel="noreferrer">Flexaust Inc.</a><br/>
              Warsaw, Indiana</p>
            <p><a href="https://www.flexaust.com/" target="_blank" rel="noreferrer">Flexaust Inc.</a><br/>
              Amesbury, Massachusetts
            </p>
            <p><a href="https://www.flexaust.com/" target="_blank" rel="noreferrer">Flexaust Inc.</a><br/>
              Newburyport, Massachusetts
            </p>
            <p><a href="https://www.flexaust.com/" target="_blank" rel="noreferrer">Flexaust Inc.</a><br/>
              Las Vegas, Nevada
            </p>
            <p><a href="https://www.flexaust.com/" target="_blank" rel="noreferrer">Flexaust Inc.</a><br/>
              Burlington, North Carolina
            </p>
            <p><a href="https://www.flexaust.com/" target="_blank" rel="noreferrer">Flexaust Inc.</a><br/>
              San Diego, California
            </p>

          </div>
        </div>


      </section>
  );
};

export default Content6;

import React from 'react';
import contentStyles from './content.module.css';

const Content3 = () => {
  return (
      <section className={contentStyles.main}>
        <div className={`container ${contentStyles.container}`}>
          <div className="container__inside"
               data-sal="fade-in"
               data-sal-delay="0"
               data-sal-easing="ease">
            <h2>Nachhaltigkeit ist<br/> unsere Verantwortung.<br/> <b>Aus
              Überzeugung.</b></h2>

            <div className={contentStyles.columns}>
              <div className={contentStyles.columnText}>
                <p>Nachhaltigkeit spielt in unserem Familienunternehmen eine große Rolle. Der verantwortungsvolle Umgang und die effiziente Verarbeitung von Rohstoffen in unseren Produktionsprozessen, die Reduzierung von Abfällen und der Einsatz erneuerbarer Energien sind dabei nur einige Beispiele für unser Engagement zur Nachhaltigkeit. Als erster deutscher Schlauchproduzent haben wir eine neue Produktreihe geschaffen, die ökologische und ökonomische Vorteile vereint – und das entlang der gesamten Wertschöpfungskette. Bereits die verwendeten Rohstoffe werden umweltschonend bei uns vor Ort produziert, um unnötig lange Lieferketten und den damit verbundenen CO2-Ausstoß zu vermeiden.</p>
                  <p>Als einer der weltweit führenden Schlauchhersteller sind wir auf der ständigen Suche nach nachhaltigen und innovativen Lösungen und deren Umsetzung. Mit unseren nachhaltigen Technologien, unseren umweltschonenden Produktionsprozessen und unseren ECO-Produkten leisten wir einen positiven Beitrag für unser Klima und unsere Umwelt und somit auch für unsere Kunden und Mitarbeiter.</p>
              </div>

              <div className={contentStyles.columnLogos}>
                <img src={'images/eco-label.svg'} alt="ECO Friendly"/>
                <img src={'images/made-in-germany-label.png'} alt="Made in Germany"/>

              </div>
            </div>

          </div>
        </div>

      </section>
  );
};

export default Content3;
import React from 'react';
import contentStyles from './content.module.css';
import {graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';

const Content7 = () => {
  const data = useStaticQuery(graphql`
      query {
          image: file(relativePath: {eq: "content7.jpg"}) {
              childImageSharp {
                  fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
      }
  `);

  return (
      <section className={contentStyles.main}>

        <div className={contentStyles.imageWrap}>
          <a href="http://www.vacuflex.cn/" target="_blank" rel="noreferrer" className={contentStyles.imageLink1}>Changxing VACUFLEX Hose Technology Co., Ltd</a>
          <a href="http://www.vacuflex.cn/" target="_blank" rel="noreferrer" className={contentStyles.imageLink3}>VACUFLEX Advanced Hoses Ltd.</a>
          <Img className={contentStyles.image}
               fluid={data.image.childImageSharp.fluid}/>
        </div>


        <div className={contentStyles.textContainer2}
             data-sal="fade-in"
             data-sal-delay="0"
             data-sal-easing="ease">
          <div className={contentStyles.textContainerInside}>
            <h3>China</h3>
            <p><a href="http://www.vacuflex.cn/" target="_blank" rel="noreferrer">Changxing VACUFLEX Hose Technology Co., Ltd</a><br/>
              Changxing</p>
            <p><a href="http://www.vacuflex.cn/" target="_blank" rel="noreferrer">VACUFLEX Advanced Hoses Ltd.</a><br/>
              Guangzhou
            </p>

          </div>
        </div>


      </section>
  );
};

export default Content7;

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content1 from '../components/content1/content1';
import Content2 from '../components/content2/content2';
import Content3 from '../components/content3/content3';
import Content4 from '../components/content4/content4';
import Content5 from '../components/content5/content5';
import Content6 from '../components/content6/content6';
import Content7 from '../components/content7/content7';

const IndexPage = () => (
  <Layout>
    <SEO title="Schauenburg Hoses" />

    <Content1/>
    <Content2/>
    <Content3/>
    <Content4/>
    <Content5/>
    <Content6/>
    <Content7/>

  </Layout>
)

export default IndexPage

import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import contentStyles from './content.module.css';
import Img from 'gatsby-image';
import DownloadButton from '../downloadButton';

const Content4 = () => {
  const data = useStaticQuery(graphql`
      query {
          desktopImage: file(relativePath: {eq: "content4.jpg"}) {
              childImageSharp {
                  fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },
          mobileImage1: file(relativePath: {eq: "content4-1-mobile.jpg"}) {
              childImageSharp {
                  fluid(maxWidth: 375) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },
          mobileImage2: file(relativePath: {eq: "content4-2-mobile.jpg"}) {
              childImageSharp {
                  fluid(maxWidth: 375) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
      }
  `);

  return (
      <section className={contentStyles.main}>

        <Img className={contentStyles.desktopImage}
             fluid={data.desktopImage.childImageSharp.fluid}/>
        <Img className={contentStyles.mobileImage1}
             fluid={data.mobileImage1.childImageSharp.fluid}/>

        <div className={contentStyles.textWrap}
             data-sal="fade-in"
             data-sal-delay="0"
             data-sal-easing="ease">
          <h2>Der neue <b>SCHAUENBURG
              HOSE TECHNOLOGY
              Katalog</b> ist da!<br/>
            Es Lohnt sich!</h2>
          <DownloadButton className={contentStyles.downloadButton} href="pdfs/Schauenburg Hose Technology Group - Product Catalogue.pdf"/>
        </div>

        <Img className={contentStyles.mobileImage2}
             fluid={data.mobileImage2.childImageSharp.fluid}/>

      </section>
  );
};

export default Content4;